import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IAlertShowOption } from '../types/AlertTypes';
import { isLoLPartner } from '../types/lol/partner.type';
import { isOverwatchPartner } from '../types/overwatch/partner.type';
import { IPartner } from '../types/PartnerTypes';
import Modal from './modal/Modal';
import OverwatchPartnerRegisterForm from './OverwatchPartnerRegisterForm';
import PartnerRegisterForm from './PartnerRegisterForm';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  partner: IPartner;
  onClose: () => void;
  showAlert: (option: IAlertShowOption) => void;
};

const PartnerEditModal: React.FC<Props> = ({
  className,
  visible,
  partner,
  onClose: handleClose,
  showAlert,
}) => {
  return (
    <Modal
      className={className}
      contentClassName="max-w-modal"
      onClose={handleClose}
      visible={visible}
    >
      <Modal.Header onClose={handleClose}>
        <h3 className="text-sm">
          <FormattedMessage id={'Edit Post'} />
        </h3>
      </Modal.Header>
      {isLoLPartner(partner) && (
        <PartnerRegisterForm
          className="p-3"
          partner={partner}
          onClose={handleClose}
          showAlert={showAlert}
        />
      )}
      {isOverwatchPartner(partner) && (
        <OverwatchPartnerRegisterForm
          className="p-3"
          partner={partner}
          onClose={handleClose}
          showAlert={showAlert}
        />
      )}
    </Modal>
  );
};

export default PartnerEditModal;
