import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IAlertShowOption } from '../types/AlertTypes';
import { IPartner } from '../types/PartnerTypes';
import Modal from './modal/Modal';
import PartnerDeleteForm from './PartnerDeleteForm';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  partner: IPartner;
  onClose: () => void;
  showAlert: (option: IAlertShowOption) => void;
};

const PartnerDeleteModal: React.FC<Props> = ({
  className,
  visible,
  partner,
  onClose: handleClose,
  showAlert,
}) => {
  return (
    <Modal
      className={className}
      contentClassName="w-96"
      onClose={handleClose}
      visible={visible}
    >
      <Modal.Header onClose={handleClose}>
        <h3 className="text-sm">
          <FormattedMessage id="Delete" />
        </h3>
      </Modal.Header>
      <PartnerDeleteForm
        className="p-3"
        partner={partner}
        onClose={handleClose}
        showAlert={showAlert}
      />
    </Modal>
  );
};

export default PartnerDeleteModal;
