import classNames from 'classnames';
import React from 'react';
import { OverwatchPlayer } from '../types/overwatch/player.type';
import { resolveOverwatchOpUrl, resolveTFTOpUrl } from '../utils/misc';
import Tooltip from './Tooltip';
import { useIntl } from 'react-intl';
import { getRegion } from '../utils/application';

type Props = {
  className?: React.HTMLAttributes<HTMLHeadingElement>['className'];
  player: OverwatchPlayer;
};

const OverwatchPlayerProfile: React.FC<Props> = ({ className, player }) => {
  const intl = useIntl();
  return (
    <h3 className={classNames('flex items-center space-x-2', className)}>
      <Tooltip title={intl.formatMessage({ id: 'See details at OP.GG' })}>
        <a
          href={resolveOverwatchOpUrl(player)}
          target="_blank"
          rel="noreferrer"
          className="truncate text-2xs tracking-tight hover:underline hover:text-green"
          onClick={(e) => e.stopPropagation()}
        >
          {player.name}
        </a>
      </Tooltip>
      <div className="text-3xs text-gray-500">#{player.tagLine}</div>
    </h3>
  );
};

export default OverwatchPlayerProfile;
