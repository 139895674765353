import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IAlertShowOption } from '../types/AlertTypes';
import { SupportedGame } from '../types/game.type';
import Modal from './modal/Modal';
import OverwatchPartnerRegisterForm from './OverwatchPartnerRegisterForm';
import PartnerRegisterForm from './PartnerRegisterForm';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  visible: boolean;
  targetGame?: SupportedGame;
  onClose: () => void;
  showAlert: (option: IAlertShowOption) => void;
};

const PartnerRegisterModal: React.FC<Props> = ({
  className,
  visible,
  targetGame = SupportedGame.LeagueOfLegends,
  onClose: handleClose,
  showAlert,
}) => {
  const headerTitle = React.useMemo(() => {
    switch (targetGame) {
      case SupportedGame.LeagueOfLegends:
        return 'Register Summoner';
      default:
        return 'Register Player';
    }
  }, [targetGame]);

  return (
    <Modal
      className={className}
      contentClassName="max-w-modal"
      onClose={handleClose}
      visible={visible}
    >
      <Modal.Header onClose={handleClose}>
        <h3 className="text-sm">
          <FormattedMessage id={headerTitle} />
        </h3>
      </Modal.Header>
      {targetGame === SupportedGame.LeagueOfLegends && (
        <PartnerRegisterForm
          className="p-3"
          onClose={handleClose}
          showAlert={showAlert}
        />
      )}
      {targetGame === SupportedGame.Overwatch && (
        <OverwatchPartnerRegisterForm
          className="p-3"
          onClose={handleClose}
          showAlert={showAlert}
        />
      )}
    </Modal>
  );
};

export default PartnerRegisterModal;
