import React, { useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Locale } from '../types/LocaleTypes';
import { championDataList } from '../data/championData';
import { divideHangulByGroups, makeRegexByCho } from 'hangul-util';
import { ReplaceDoubleFinalConsonants } from '../utils/string';
import { ErrorCode } from '../types/ErrorTypes';

interface Champion {
  key: string;
  name: {
    [key in string]: string;
  };
  image_url: string;
}

interface Props {
  title?: string;
  selectedChampionsTitle: string;
  maxChampion: number;
  selectedChampions: string[];
  setSelectedChampions: React.Dispatch<React.SetStateAction<string[]>>;
  showErrorAlert: (
    errorCode: ErrorCode,
    value?: Record<string, string>
  ) => void;
}

const ChampionSelect: React.FC<Props> = ({
  title = 'Champions to Play',
  selectedChampionsTitle,
  maxChampion,
  selectedChampions,
  setSelectedChampions,
  showErrorAlert,
}) => {
  const handleClickChampion = (key: string) => {
    if (selectedChampions.length >= maxChampion) {
      showErrorAlert(ErrorCode.LessThanNumber, { number: `${maxChampion}` });
      return;
    }

    if (selectedChampions.includes(key)) {
      showErrorAlert(ErrorCode.CantSelectDuplicate);
      return;
    }

    setSelectedChampions([...selectedChampions, key]);
  };
  const handleClickSelectedChampion = (championKey: string) => () => {
    setSelectedChampions(
      selectedChampions.filter((champion) => champion !== championKey)
    );
  };

  return (
    <Stack direction={'row'} spacing={1.5}>
      <Box
        sx={{
          width: { xs: 'auto', lg: '226px' },
        }}
      >
        <label className="block text-2xs text-gray-400 mb-2">
          <FormattedMessage id={title} />
        </label>
        <ChampionSelectMain onClickChampionCallback={handleClickChampion} />
      </Box>
      <Stack flex={1}>
        <label className={'block text-2xs text-gray-400 mb-2'}>
          <FormattedMessage id={selectedChampionsTitle} />
        </label>
        <div className={'flex items-center h-[47px]'}>
          {selectedChampions &&
            selectedChampions.map((champion) => (
              <SelectedChampion
                key={champion}
                championKey={champion}
                onClick={handleClickSelectedChampion(champion)}
              />
            ))}
        </div>
      </Stack>
    </Stack>
  );
};

const ChampionSelectMain = ({
  onClickChampionCallback,
}: {
  onClickChampionCallback: (key: string) => void;
}) => {
  const intl = useIntl();
  const { locale = Locale.KO } = useRouter();
  const ref = useRef<HTMLDivElement>(null);

  const [searchedChampions, setSearchedChampions] = useState<Champion[]>([]);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');

  const handleClickChampion = (key: string) => () => {
    setInput('');
    setInputFocus(false);
    onClickChampionCallback(key);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setInput(input);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setInputFocus(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    let filteredChampions = Object.values(championDataList).sort((a, b) =>
      a.name[locale as Locale].localeCompare(b.name[locale as Locale])
    );

    if (input.trim()) {
      const lowerInput = input.toLowerCase().trim();

      filteredChampions = filteredChampions.filter((champion) => {
        const championNames = Object.values(champion.name);
        const isIncluded = championNames.some(
          (name) =>
            name.toLowerCase().replaceAll(' ', '').includes(lowerInput) ||
            name.toLowerCase().includes(lowerInput)
        );

        if (locale === Locale.KO) {
          const input = ReplaceDoubleFinalConsonants(lowerInput);
          const name = champion.name['ko'];
          return (
            isIncluded ||
            makeRegexByCho(input).test(name) ||
            makeRegexByCho(input).test(name.replaceAll(' ', ''))
          );
        }

        return isIncluded;
      });

      if (locale === Locale.KO) {
        filteredChampions.sort((a, b) => {
          const nameA = a.name[Locale.KO];
          const nameB = b.name[Locale.KO];
          const inputPosA = nameA.indexOf(lowerInput);
          const inputPosB = nameB.indexOf(lowerInput);

          if (inputPosA !== inputPosB) {
            return inputPosA - inputPosB;
          } else {
            if (inputPosA !== -1) {
              return nameA.length - nameB.length;
            } else {
              // 초성일 경우
              const dividedAChos: any = divideHangulByGroups(nameA).reduce(
                (acc, cur: any) => acc + cur[0],
                ''
              );
              const dividedBChos: any = divideHangulByGroups(nameB).reduce(
                (acc, cur: any) => acc + cur[0],
                ''
              );
              const inputPosA = dividedAChos.indexOf(lowerInput);
              const inputPosB = dividedBChos.indexOf(lowerInput);
              if (inputPosA !== inputPosB) {
                return inputPosA - inputPosB;
              } else {
                return dividedAChos.length - dividedBChos.length;
              }
            }
          }
        });
      }
    }
    setSearchedChampions(filteredChampions);
  }, [input]);

  return (
    <div ref={ref} className={'relative'}>
      <input
        type="text"
        placeholder={intl.formatMessage({
          id: 'Champion Name',
        })}
        className={classNames(
          'block w-full h-[47px] p-3 border rounded border-solid border-gray-600 text-sm focus:outline-none bg-gray-850 text-gray-100'
        )}
        value={input}
        onChange={handleChangeInput}
        onFocus={() => setInputFocus(true)}
      />
      {inputFocus && searchedChampions.length > 0 && (
        <div className="max-h-[120px] overflow-y-scroll absolute top-[47px] left-0 right-0 z-10 border-b border-solid border-gray-600">
          {searchedChampions.map((champion) => (
            <div
              className={`flex items-center pl-3 pr-3 h-[40px] border-l border-r border-solid border-gray-600 bg-gray-850 cursor-pointer font-roboto text-sm`}
              key={champion.image_url}
              onClick={handleClickChampion(champion.key)}
            >
              <img
                className={'w-6 h-6 rounded-xl mr-2'}
                src={`//opgg-static.akamaized.net/meta/images/lol/latest/champion/${champion.key}.png?image=c_crop,h_103,w_103,x_9,y_9/q_auto,f_webp,w_64&v=1681967972734`}
              />
              <div className={'max-w-[168px] overflow-x-hidden text-ellipsis'}>
                {champion.name[locale]}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SelectedChampion: React.FC<{
  championKey: string;
  onClick: () => void;
}> = ({ championKey, onClick }) => (
  <div className={'relative mr-2 cursor-pointer'} onClick={onClick}>
    <img
      className={'w-10 h-10 rounded-full'}
      src={`//opgg-static.akamaized.net/meta/images/lol/latest/champion/${championKey}.png?image=c_crop,h_103,w_103,x_9,y_9/q_auto,f_webp,w_64&v=1681967972734`}
    />
    <div
      className={
        'flex justify-center items-center absolute right-0 top-0 bg-[rgba(0,0,0,0.6)] rounded-[2px]'
      }
    >
      <img src={'/images/icons/close_small.svg'} />
    </div>
  </div>
);

export default ChampionSelect;
