import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { InValidPasscodeError } from '../errors/PartnerErrors';
import { usePartnerOptions } from '../hooks/partners';
import { useDeleteOverwatchPartner } from '../mutations/overwatch/partners';
import { useDeletePartner } from '../mutations/partners';
import { IAlertShowOption, ITranslateMessage } from '../types/AlertTypes';
import { ErrorCode } from '../types/ErrorTypes';
import { IPartner, PartnerOption } from '../types/PartnerTypes';
import { isLoLPartner } from '../types/lol/partner.type';
import { OverwatchPartner } from '../types/overwatch/partner.type';
import { ErrorMessageMap } from './Alert';
import PasscodeInput from './PasscodeInput';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import { getSummonerName } from '../utils/tag';
import FullSummonerProfile from './SummonerProfile/FullSummonerProfile';

interface Props {
  partner: IPartner;
  onClose: () => void;
  showAlert(option: IAlertShowOption): void;
  className?: string;
}

const PartnerDeleteForm: React.FC<Props> = ({
  className,
  partner,
  onClose: handleClose,
  showAlert,
}) => {
  const { deletePartner, loading } = isLoLPartner(partner)
    ? useDeletePartner()
    : useDeleteOverwatchPartner();

  const { register, handleSubmit } = useForm();
  const { hasOption } = usePartnerOptions(partner);

  const options = useMemo(() => {
    return {
      premium: partner?.isSubscription,
      certified: hasOption(PartnerOption.CERTIFIED),
      mic: hasOption(PartnerOption.MIC),
    };
  }, [hasOption]);

  return (
    <form
      className={classNames(className)}
      onSubmit={handleSubmit(handleDeleteForm, handleDeleteFormErrors)}
    >
      <div className="text-center text-sm mb-3">
        <FormattedMessage
          id="Are you sure you want to delete?"
          values={{ br: <br /> }}
        />
      </div>
      {isLoLPartner(partner) && (
        <div className={'w-full flex justify-center'}>
          <FullSummonerProfile
            copy={false}
            options={options}
            summoner={partner.summoner}
          />
        </div>
      )}
      {!options?.certified && (
        <PasscodeInput
          {...register('passcode', { required: !options?.certified })}
        />
      )}

      <div className="flex mt-4 ml-auto">
        <button
          type="button"
          className="cancel flex-1 p-0 rounded"
          onClick={handleClose}
        >
          <FormattedMessage id="Cancel" />
        </button>
        <button
          type="submit"
          className="button button--destructive ml-3 flex-1 p-0 rounded h-12"
        >
          {loading ? (
            <FormattedMessage id="Deleting..." />
          ) : (
            <FormattedMessage id="Delete" />
          )}
        </button>
      </div>
    </form>
  );

  function handleDeleteFormErrors(
    errors: FieldErrors<{ passcode: string }>
  ): void {
    errors.passcode &&
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.PASSWORD],
        },
        errorCode: ErrorCode.PASSWORD,
      });
  }

  function showErrorAlert(errorCode: ErrorCode): void {
    const messageKey =
      ErrorMessageMap[errorCode] || ErrorCode.UnexpectedException;

    showAlert({
      message: {
        key: messageKey,
      },
      errorCode,
    });
  }

  async function handleDeleteForm({
    passcode,
  }: {
    passcode?: string;
  }): Promise<void> {
    try {
      const response = await deletePartner({
        variables: {
          id: partner.id,
          passcode,
        },
      });

      if (response.errors) {
        throw new InValidPasscodeError();
      }

      if (Boolean(response.data)) {
        showAlert({
          message: getTranslateMessage(partner),
          errorCode: null,
        });
        dataLayer(
          {
            op_event: GTMEventInfoType.SUBMIT_DELETE_POST,
          },
          GTMEventType.SUBMIT
        );
        handleClose();
      }
    } catch (err) {
      if (err instanceof Error) {
        showErrorAlert(err.message as ErrorCode);
      }
    }
  }

  function getTranslateMessage(partner: IPartner): ITranslateMessage {
    if (isLoLPartner(partner)) {
      return {
        key: '{name}’s post has been deleted.',
        value: { name: getSummonerName(partner.summoner) },
      };
    }
    return {
      key: '{name}’s post has been deleted.',
      value: { name: (partner as OverwatchPartner).player.name },
    };
  }
};

export default PartnerDeleteForm;
