import { Box, Collapse, Stack } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNoteFilter } from '../hooks/filters';
import { usePartnerOptions } from '../hooks/partners';
import { useGlobalState } from '../hooks/states';
import {
  IRegisterPartnerParams,
  IUpdatePartnerParams,
  useAddPartner,
  useEditPartner,
} from '../mutations/partners';
import { IAlertShowOption } from '../types/AlertTypes';
import { ErrorCode } from '../types/ErrorTypes';
import { PartnerOption } from '../types/PartnerTypes';
import { Nullable, Optional } from '../types/UtilTypes';
import { LoLPartner, Position, QueueType } from '../types/lol/partner.type';
import { getRegion } from '../utils/application';
import { isClashSplitOne } from '../utils/date';
import { ErrorMessageMap } from './Alert';
import OptionSwitch from './OptionSwitch';
import PasscodeInput from './PasscodeInput';
import QueueTypeSelect from './QueueTypeSelect';
import dayjs from 'dayjs';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import { useCertifiedSummoners } from '../queries/summoners';
import { isNullArray } from '../utils/array';
import RsoNoteInput from './RsoNoteInput';
import RsoNoteSelect from './RsoNoteSelect';
import { useMember } from '../queries/member';
import PositionSelect from './PositionSelect';
import NexusPositionSelect, {
  RequiredNexusPositionSelect,
} from './NexusPositionSelect';
import RequiredPositionSelect from './RequiredPositionSelect';
import { useRouter } from 'next/router';
import {
  addRequestParamsWithSummonerName,
  getSummonerName,
} from '../utils/tag';
import ChampionSelect from './ChampionSelect';
import useRsoNote from '../hooks/rsoNote';
import FullSummonerProfile from './SummonerProfile/FullSummonerProfile';
import SummonerName from './SummonerName';
import RegisterPolicyText from './RegisterPolicyText';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  partner?: LoLPartner;
  showAlert: (option: IAlertShowOption) => void;
  onClose: () => void;
};

export type PartnerFormType = {
  passcode: string;
  position: Nullable<Position>;
  requiredPosition: Position[];
  queueType: QueueType;
  options: number;
  recaptcha: Nullable<string>;
  summonerName?: string;
  champion?: string;
  champion2?: string;
  position2?: Nullable<Position>;
};

const PartnerRegisterForm: React.FC<Props> = ({
  className,
  partner,
  showAlert,
  onClose: handleClose,
}) => {
  const intl = useIntl();
  const { pathname } = useRouter();
  const isNexus = useMemo(
    () => pathname.startsWith('/nexus-blitz'),
    [pathname]
  );

  const {
    addPartner,
    loading: addPartnerLoading,
    error: addPartnerError,
  } = useAddPartner();
  const {
    editPartner,
    loading: editPartnerLoading,
    error: editPartnerError,
  } = useEditPartner();
  const { filterNote } = useNoteFilter();
  const [{ queueType }, setGlobalState] = useGlobalState();
  const { summoners: certifiedSummoners } = useCertifiedSummoners();
  const { user } = useMember();
  const isCertified = useMemo(() => {
    return !isNullArray(certifiedSummoners);
  }, [certifiedSummoners]);
  const [note, setNote] = useRsoNote({
    isCertified,
    partnerNote: partner?.note,
  });

  const addPartnerBannedExpiresAt = useMemo(() => {
    const expiresAt = (
      addPartnerError?.graphQLErrors[0]?.extensions?.banErrorData as {
        expiresAt?: string;
      }
    )?.expiresAt;

    if (expiresAt) {
      return dayjs(expiresAt).format('YYYY-MM-DD');
    }
    return undefined;
  }, [addPartnerError?.graphQLErrors]);

  const editPartnerBannedExpiresAt = useMemo(() => {
    const expiresAt = (
      editPartnerError?.graphQLErrors[0]?.extensions?.banErrorData as {
        expiresAt?: string;
      }
    )?.expiresAt;

    if (expiresAt) {
      return dayjs(expiresAt).format('YYYY-MM-DD');
    }
    return undefined;
  }, [editPartnerError?.graphQLErrors]);

  const {
    hasOption,
    toggleOption,
    rawValue: rawOptions,
  } = usePartnerOptions(partner);

  const formMethods = useForm<PartnerFormType>({
    defaultValues: partner
      ? {
          ...partner,
          requiredPosition: partner?.requiredPosition || [],
        }
      : {
          queueType,
          position: null,
          position2: null,
          requiredPosition: [],
        },
    mode: 'all',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;

  const [certified, setCertified] = React.useState(false);
  const [animated, setAnimated] = React.useState(false);

  const [selectedChampions, setSelectedChampions] = useState<string[]>(
    partner?.champion ? [partner.champion] : []
  );
  const [selectedChampions2, setSelectedChampions2] = useState<string[]>(
    partner?.champion2 ? [partner.champion2] : []
  );

  const isClashPeriod = React.useMemo(() => isClashSplitOne(), []);

  const handleSelectSummoner = React.useCallback((summonerName: string) => {
    setValue('summonerName', summonerName);
    setCertified(summonerName.length !== 0);
  }, []);

  const handleAnimated = React.useCallback(() => setAnimated(true), []);

  useEffect(() => {
    register('position', { required: true });
    register('queueType');
  }, []);

  useEffect(() => {
    if (!partner) {
      register('summonerName', { required: true });
      return;
    }

    const certified = hasOption(PartnerOption.CERTIFIED);
    setCertified(certified);
  }, [partner, hasOption]);

  useEffect(() => {
    if (
      addPartnerError?.graphQLErrors &&
      addPartnerError?.message === ErrorCode.BannedIP
    ) {
      showErrorAlert(addPartnerError?.message as ErrorCode);
    }
  }, [addPartnerError]);

  useEffect(() => {
    if (
      editPartnerError?.graphQLErrors &&
      editPartnerError?.message === ErrorCode.BannedIP
    ) {
      showErrorAlert(editPartnerError?.message as ErrorCode);
    }
  }, [editPartnerError]);

  return (
    <FormProvider {...formMethods}>
      <form
        className={classNames(className)}
        onSubmit={handleSubmit(
          handleFormSubmit as SubmitHandler<PartnerFormType>,
          handleEditFormErrors
        )}
      >
        {isClashPeriod && (
          <div className="mb-4 bg-yellow-400 rounded px-3 py-2">
            <p className="text-2xs text-black break-words">
              <FormattedMessage id="announce-bar.clash" />
            </p>
          </div>
        )}

        {partner ? (
          <FullSummonerProfile
            options={{
              certified,
              mic: hasOption(PartnerOption.MIC),
              premium: partner?.isSubscription,
            }}
            summoner={partner.summoner}
          />
        ) : (
          <SummonerName
            certified={certified}
            inputProps={register('summonerName')}
            onSummonerSelect={handleSelectSummoner}
          />
        )}

        <div className="flex flex-col gap-3 items-start my-3">
          <div className="flex flex-row items-stretch gap-3">
            <div>
              <label className="block text-2xs text-gray-400 mb-2">
                <FormattedMessage
                  id={
                    watch('queueType') !== QueueType.NORMAL
                      ? 'My Position'
                      : 'Primary'
                  }
                />
              </label>
              {!isNexus ? (
                <PositionSelect
                  position={watch('position')}
                  onChange={handlePosition}
                />
              ) : (
                <NexusPositionSelect
                  position={watch('position')}
                  onChange={handlePosition}
                />
              )}
            </div>
            {!isNexus && (
              <div>
                <label className="block text-2xs text-gray-400 mb-2">
                  <FormattedMessage id="Queue Type" />
                </label>
                <QueueTypeSelect
                  queueTypes={Object.values(QueueType).filter(
                    (queueType) =>
                      queueType !== QueueType.ARAM &&
                      queueType !== QueueType.NEXUS_BLITZ
                  )}
                  selected={watch('queueType')}
                  onChange={handleQueueType}
                  className="inline-block"
                />
              </div>
            )}
            <div className="flex flex-col">
              <label className="block text-2xs text-gray-400 mb-2">
                <FormattedMessage id="MIC" />
              </label>

              <div className="flex-1 flex items-center">
                <OptionSwitch
                  enabled={hasOption(PartnerOption.MIC)}
                  onChange={handleChangeOption}
                />
              </div>
            </div>
          </div>
          {watch('queueType') === QueueType.NORMAL && (
            <div className="flex flex-col gap-3">
              <ChampionSelect
                selectedChampionsTitle={'Selected Primary Champion'}
                maxChampion={1}
                selectedChampions={selectedChampions}
                setSelectedChampions={setSelectedChampions}
                showErrorAlert={showErrorAlert}
              />
              <div className="max-w-[223px]">
                <label className="block text-2xs text-gray-400 mb-2">
                  <FormattedMessage id={'Secondary'} />
                </label>
                <PositionSelect
                  position={watch('position2')}
                  onChange={handlePosition2}
                />
              </div>
              <ChampionSelect
                selectedChampionsTitle={'Selected Secondary Champion'}
                maxChampion={1}
                selectedChampions={selectedChampions2}
                setSelectedChampions={setSelectedChampions2}
                showErrorAlert={showErrorAlert}
              />
            </div>
          )}
          <div>
            <label className="block text-2xs text-gray-400 mb-2">
              <FormattedMessage id="Position I'm looking for" />
            </label>
            {!isNexus ? (
              <RequiredPositionSelect
                positions={watch('requiredPosition')}
                onChange={handleRequiredPosition}
              />
            ) : (
              <RequiredNexusPositionSelect
                positions={watch('requiredPosition')}
                onChange={handleRequiredPosition}
              />
            )}
          </div>
        </div>

        <label className="block text-2xs text-gray-400 mb-2 mt-3">
          <FormattedMessage id="Note" />
        </label>
        {isCertified ? (
          <RsoNoteInput value={note} setValue={setNote} />
        ) : (
          <RsoNoteSelect selected={note} setValue={setNote} />
        )}

        <Collapse
          in={!certified}
          timeout={animated ? 'auto' : 0}
          addEndListener={handleAnimated}
        >
          <PasscodeInput
            className="w-44"
            {...register('passcode', {
              required: {
                value: !certified,
                message: intl.formatMessage({ id: `Can't be blank` }),
              },
              minLength: {
                value: 4,
                message: intl.formatMessage({ id: 'Enter 4 digits, please.' }),
              },
              maxLength: {
                value: 4,
                message: intl.formatMessage({ id: 'Enter 4 digits, please.' }),
              },
              pattern: {
                value:
                  /^(?!(\d)\1\1\1|(0123)|(1234)|(2345)|(3456)|(4567)|(5678)|(6789)|(7890)|(0987)|(9876)|(8765)|(7654)|(6543)|(5432)|(4321)|(3210)|\D).*$/,
                message: intl.formatMessage({
                  id: 'Cannot use consecutive number sequences or repetitions.',
                }),
              },
            })}
          />
        </Collapse>

        {errors?.passcode && (
          <span className="block text-red-500 m-0 my-0.5 text-sm">
            {errors.passcode.message}
          </span>
        )}

        {!partner && <RegisterPolicyText className={'mt-3'} />}

        <div className="flex mt-4 ml-auto">
          <button
            type="button"
            className="cancel flex-1 p-0 rounded"
            onClick={() => {
              dataLayer(
                {
                  op_event: GTMEventInfoType.CLICK_CANCLE_POST,
                },
                GTMEventType.CLICK
              );
              handleClose();
            }}
            tabIndex={-1}
          >
            <FormattedMessage id="Cancel" />
          </button>
          <button
            type="submit"
            className="ml-3 flex-1 p-0 rounded h-12 button button--submit"
            tabIndex={-1}
            disabled={addPartnerLoading || editPartnerLoading}
          >
            {addPartnerLoading || editPartnerLoading ? (
              <FormattedMessage id="Saving..." />
            ) : (
              <FormattedMessage id={partner ? 'Edit' : 'Registration'} />
            )}
          </button>
        </div>
      </form>
    </FormProvider>
  );

  function handleChangeOption(): void {
    toggleOption(PartnerOption.MIC);
  }

  function handlePosition(position: Position): void {
    setValue('position', position);
  }

  function handlePosition2(position: Position): void {
    setValue('position2', position);
  }

  function handleRequiredPosition(positions: Position[]): void {
    setValue('requiredPosition', positions);
  }

  function handleQueueType(queue: QueueType): void {
    setValue('queueType', queue);
  }

  function handleEditFormErrors(
    errors: FieldErrors<{
      summonerName: string;
      position: Position;
      passcode: string;
      champion: string;
    }>
  ): void {
    if (errors.summonerName) {
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.SUMMONER],
        },
        errorCode: ErrorCode.SUMMONER,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'summonerName',
        },
        GTMEventType.SCREEN_VIEW
      );
    } else if (errors.position) {
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.POSITION],
        },
        errorCode: ErrorCode.POSITION,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'position',
        },
        GTMEventType.SCREEN_VIEW
      );
    } else if (errors.passcode) {
      const errCode =
        ErrorCode[
          errors.passcode.type === 'pattern'
            ? 'InvalidPatternPasscode'
            : 'PASSWORD'
        ];
      showAlert({
        message: {
          key: ErrorMessageMap[errCode],
        },
        errorCode: errCode,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'passcode',
        },
        GTMEventType.SCREEN_VIEW
      );
    }
  }

  async function handleFormSubmit(
    data: IRegisterPartnerParams & IUpdatePartnerParams
  ): Promise<void> {
    if (addPartnerLoading || editPartnerLoading) return;

    if (data.queueType === QueueType.NORMAL) {
      if (selectedChampions.length === 0) {
        showAlert({
          message: {
            key: ErrorMessageMap[ErrorCode.CHAMPION],
          },
          errorCode: ErrorCode.CHAMPION,
        });
        return;
      }

      if (!data.position2) {
        showAlert({
          message: {
            key: ErrorMessageMap[ErrorCode.POSITION2],
          },
          errorCode: ErrorCode.POSITION2,
        });
        return;
      }

      if (selectedChampions2.length === 0) {
        showAlert({
          message: {
            key: ErrorMessageMap[ErrorCode.CHAMPION2],
          },
          errorCode: ErrorCode.CHAMPION2,
        });
        return;
      }

      if (selectedChampions[0] === selectedChampions2[0]) {
        showAlert({
          message: {
            key: ErrorMessageMap[ErrorCode.SameChampion],
          },
          errorCode: ErrorCode.SameChampion,
        });
        return;
      }

      if (data.position === data.position2) {
        showAlert({
          message: {
            key: ErrorMessageMap[ErrorCode.SamePosition],
          },
          errorCode: ErrorCode.SamePosition,
        });
        return;
      }
    }

    try {
      const submit = partner ? handleEditPartner : handleAddPartner;
      const request = {
        ...data,
        note: filterNote(note),
        passcode: certified ? '' : data.passcode,
      };

      if (selectedChampions.length > 0) {
        request.champion = selectedChampions[0];
      }

      if (selectedChampions2.length > 0) {
        request.champion2 = selectedChampions2[0];
      }

      const response = await submit(request);

      if (!response) return;

      if (!isNexus) {
        setGlobalState({
          queueType: data?.queueType?.toUpperCase() as QueueType,
        });
      }

      handleClose();
    } catch (err) {
      if (err instanceof Error) {
        showErrorAlert(err.message as ErrorCode);
      }
    }
  }

  async function handleEditPartner(
    data: IUpdatePartnerParams
  ): Promise<Optional<LoLPartner>> {
    try {
      if (!partner) {
        throw new Error('userId must not be a nullable value.');
      }

      const response = await editPartner({
        variables: {
          ...data,
          id: partner.id,
          options: rawOptions,
          queueType: isNexus ? QueueType.NEXUS_BLITZ : data.queueType,
        },
      });

      if (!response.data || !response.data.updatePartner) return;

      if (response.data?.updatePartner) {
        showAlert({
          message: {
            key: '{name} summoner updated successfully.',
            value: { name: getSummonerName(partner.summoner) },
          },
          errorCode: null,
        });
        dataLayer(
          {
            op_event: GTMEventInfoType.SUBMIT_EDIT_POST,
          },
          GTMEventType.SUBMIT
        );
        handleClose();
      }

      return response.data.updatePartner;
    } catch (e) {
      throw e;
    }
  }

  async function handleAddPartner(
    data: IRegisterPartnerParams
  ): Promise<Optional<LoLPartner>> {
    try {
      const { summonerName } = getValues();
      if (!summonerName) return;

      const variables = {
        ...data,
        region: getRegion(),
        options: rawOptions,
        queueType: isNexus ? QueueType.NEXUS_BLITZ : data.queueType,
      };

      const response = await addPartner({
        variables: addRequestParamsWithSummonerName(summonerName, variables),
      });

      if (!response.data || !response.data.addPartner) return;

      dataLayer(
        {
          op_event: GTMEventInfoType.SUBMIT_POST,
        },
        GTMEventType.SUBMIT
      );

      return response.data.addPartner;
    } catch (e) {
      throw e;
    }
  }

  function showErrorAlert(
    errorCode: ErrorCode,
    value?: Record<string, string>
  ): void {
    const messageKey =
      ErrorMessageMap[errorCode] || ErrorCode.UnexpectedException;

    const _value = (() => {
      if (errorCode === ErrorCode.PartnerCreationLimited) {
        return {
          minutes: Boolean(getValues('passcode')) ? 60 : 10,
        };
      } else if (errorCode === ErrorCode.BannedIP) {
        return {
          expiresAt: addPartnerBannedExpiresAt || editPartnerBannedExpiresAt,
        };
      }
      return {};
    })() as Record<string, string>;

    showAlert({
      message: {
        key: messageKey,
        value: value ?? _value,
      },
      errorCode,
    });
  }
};

export default PartnerRegisterForm;
