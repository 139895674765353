import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { noop } from '../utils/misc';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';

interface Props<T> {
  queueTypes: T[];
  selected?: T;
  className?: string;
  onChange?: (queueType: T) => void;
  readonly?: boolean;
}

const QueueTypeSelect = <T extends string>({
  queueTypes,
  selected,
  onChange: handleChange = noop,
  className,
  readonly,
}: Props<T>) => {
  const intl = useIntl();

  return (
    <div className={classNames('select-container', className)}>
      <select
        name="queue_type"
        value={selected}
        onChange={handleChangeQueueType}
        className={classNames('cursor-default', {
          'cursor-pointer': !readonly,
        })}
        disabled={readonly}
      >
        {queueTypes.map((queue: T) => (
          <option key={queue} value={queue}>
            {intl.formatMessage({ id: queue })}
          </option>
        ))}
      </select>
    </div>
  );

  function handleChangeQueueType(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const selected = event.target.value as T;

    if (readonly) return;

    handleChange(selected);
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_MODE_FILTER,
        op_event_value: selected,
      },
      GTMEventType.CLICK
    );
  }
};

export default QueueTypeSelect;
