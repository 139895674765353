import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReportPartner } from '../mutations/partners';
import { IAlertShowOption } from '../types/AlertTypes';
import { IPartner, PartnerOption } from '../types/PartnerTypes';
import { Nullable } from '../types/UtilTypes';
import { SupportedGame } from '../types/game.type';
import { isLoLPartner } from '../types/lol/partner.type';
import FullSummonerProfile from './SummonerProfile/FullSummonerProfile';
import { usePartnerOptions } from '../hooks/partners';

type Props = {
  partner: IPartner;
  onClose: () => void;
  showAlert(option: IAlertShowOption): void;
  className?: string;
};

interface IReportFormState {
  note: Nullable<string>;
  isInValid: boolean;
}

const PartnerReportForm: React.FC<Props> = ({
  partner,
  onClose: handleClose,
  showAlert,
  className,
}) => {
  const intl = useIntl();

  const { reportPartner, loading } = useReportPartner();

  const { hasOption } = usePartnerOptions(partner);

  const options = useMemo(() => {
    return {
      premium: partner?.isSubscription,
      certified: hasOption(PartnerOption.CERTIFIED),
      mic: hasOption(PartnerOption.MIC),
    };
  }, [hasOption]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IReportFormState>();

  return (
    <form
      className={classNames(className)}
      onSubmit={handleSubmit(handleReportSubmit)}
    >
      {isLoLPartner(partner) && (
        <FullSummonerProfile options={options} summoner={partner.summoner} />
      )}
      <label className="block text-2xs text-gray-400 mb-2 mt-3">
        <FormattedMessage id="Tell us why" />
      </label>
      <input
        type="text"
        className="w-full mb-2 p-3 border rounded border-solid border-gray-600 text-gray-100 text-sm focus:outline-none bg-gray-850"
        placeholder={intl.formatMessage({
          id: 'Tell us why you report this partner',
        })}
        {...register('note', { required: true })}
      />

      {errors.note && (
        <span className="block text-red-500 m-0 my-0.5 text-sm mb-2">
          <FormattedMessage id="Can't be blank" />
        </span>
      )}

      <div className="flex flex-row items-center">
        <img src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/info.svg" />
        <p className="text-2xs text-red-500 ml-1">
          <FormattedMessage id="If you report without proper reason, you may be permanently banned." />
        </p>
      </div>

      <div className="flex mt-4 ml-auto">
        <button
          type="button"
          className="cancel flex-1 p-0 rounded"
          onClick={handleClose}
        >
          <FormattedMessage id="Cancel" />
        </button>
        <button
          type="submit"
          className="button button--destructive ml-3 flex-1 p-0 rounded h-12"
        >
          {loading ? (
            <FormattedMessage id="Saving..." />
          ) : (
            <FormattedMessage id="Report" />
          )}
        </button>
      </div>
    </form>
  );

  async function handleReportSubmit(): Promise<void> {
    try {
      const response = await reportPartner({
        variables: {
          note: getValues('note'),
          partnerId: partner.id,
          gameKind: isLoLPartner(partner)
            ? SupportedGame.LeagueOfLegends
            : SupportedGame.Overwatch,
        },
      });

      if (response.data?.addReport) {
        showAlert({
          message: {
            key: 'You have reported successfully.',
          },
          errorCode: null,
        });
        handleClose();
      }
    } catch (e) {
      console.error(`Error occured handle report partner`, e);
    }
  }
};

export default PartnerReportForm;
