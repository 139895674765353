import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '../components/Tooltip';
import { OverwatchPosition } from '../types/overwatch/partner.type';

interface Props {
  position: OverwatchPosition;
  readonly?: boolean;
  filled?: boolean;
  size?: 'default' | 'small';
}

const POSITION_PATH: {
  [key in keyof OverwatchPosition as Uppercase<OverwatchPosition>]: string[];
} = {
  ALL: [
    'M16.2928076,17.0307921 C16.6549683,17.6584306 16.4399705,18.4608135 15.8125108,18.8232841 C15.1844161,19.1872046 14.3817451,18.9719172 14.0194579,18.3440595 L11.8751153,14.6269842 L9.73077279,18.3440595 C9.36848556,18.9719172 8.56581451,19.1872046 7.93795682,18.8249174 C7.31026014,18.4608135 7.09526232,17.6584306 7.45742309,17.0307921 L9.60202464,13.3132678 L5.31251922,13.3132678 C4.58763487,13.3132678 4,12.7256329 4,12.0007486 C4,11.2743674 4.58763487,10.6867325 5.31251922,10.6867325 L9.60202464,10.6867325 L7.45742309,6.96920825 C7.09526232,6.34156972 7.31026014,5.53918682 7.93771981,5.17671625 C8.56581451,4.8127957 9.36848556,5.0280831 9.73077279,5.65594079 L11.8751153,9.37301611 L14.0194579,5.65594079 C14.3817451,5.0280831 15.1844161,4.8127957 15.8122738,5.17508293 C16.4399705,5.53918682 16.6549683,6.34156972 16.2928076,6.96920825 L14.148206,10.6867325 L18.4377114,10.6867325 C19.1625958,10.6867325 19.7502307,11.2743674 19.7502307,11.9992518 C19.7502307,12.7256329 19.1625958,13.3132678 18.4377114,13.3132678 L14.148206,13.3132678 L16.2928076,17.0307921 Z',
  ],
  TANK: [
    'm5.4398 34.59v-24.069c0-3.8588 8.0447-8.9157 24.723-8.9157 16.678 0 24.723 5.0568 24.723 8.9157v24.069c0 5.821-19.817 24.133-24.723 24.133-4.9053 0-24.723-18.312-24.723-24.133z',
  ],
  DAMAGE: [
    'm36.451 58.73v-9.6006h-12.577v9.6006zm0-12.997v-34.224c0-5.2977-5.0459-9.8967-6.2886-9.8967s-6.2886 4.599-6.2886 9.8967v34.224zm18.777 12.997v-9.6006h-12.577v9.6006zm0-12.997v-34.224c0-5.2977-5.0459-9.8967-6.2886-9.8967s-6.2886 4.599-6.2886 9.8967v34.224zm-37.553 12.997v-9.6006h-12.577v9.6006zm0-12.997v-34.224c0-5.2977-5.0459-9.8967-6.2886-9.8967s-6.2886 4.599-6.2886 9.8967v34.224z',
  ],
  SUPPORT: [
    'm40.777 54.38c0 1.8962-1.6187 4.3473-3.6536 4.3473h-13.922c-2.0349 0-3.6536-2.4511-3.6536-4.3473v-13.597h-13.597c-1.8962 0-4.3473-1.6187-4.3473-3.6536v-13.922c0-2.0349 2.4511-3.6536 4.3473-3.6536h13.597v-13.597c0-1.8962 1.6187-4.3473 3.6536-4.3473h13.922c2.0349 0 3.6536 2.4511 3.6536 4.3473v13.597h13.597c1.8962 0 4.3473 1.6187 4.3473 3.6536v13.922c0 2.0349-2.4511 3.6536-4.3473 3.6536h-13.597z',
  ],
};

const OverwatchPositionIcon: React.FC<Props> = ({
  position: pos,
  readonly,
  filled,
  size = 'default',
}) => {
  const position = [
    OverwatchPosition.All,
    OverwatchPosition.Tank,
    OverwatchPosition.Damage,
    OverwatchPosition.Support,
  ].includes(pos)
    ? pos
    : OverwatchPosition.All;

  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id:
          position === OverwatchPosition.All
            ? 'All roles'
            : `overwatch.role.${position.toLowerCase()}`,
      })}
    >
      <svg
        className={classNames(
          readonly && 'opacity-40',
          position === OverwatchPosition.All && 'w-6 h-6',
          {
            'w-5 h-5': size === 'default',
            'w-2.5 h-2.5': size === 'small',
            'position-icon': !filled,
          }
        )}
        viewBox={
          position === OverwatchPosition.All ? '0 0 24 24' : '0 0 60.325 60.325'
        }
        xmlns="http://www.w3.org/2000/svg"
        fill={filled ? 'currentColor' : undefined}
      >
        {(POSITION_PATH[position] ?? []).map((path: string, i) => (
          <path key={i} d={path} />
        ))}
      </svg>
    </Tooltip>
  );
};

export default OverwatchPositionIcon;
