import { Box, Collapse, Grow, Stack } from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FieldErrors, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import striptags from 'striptags';
import { useNoteFilter } from '../hooks/filters';
import { usePartnerOptions } from '../hooks/partners';
import { useOverwatchState } from '../hooks/states';
import {
  OverwatchPartnerRegisterProps,
  OverwatchPartnerUpdateProps,
  useAddOverwatchPartner,
  useEditOverwatchPartner,
} from '../mutations/overwatch/partners';
import { IAlertShowOption } from '../types/AlertTypes';
import { ErrorCode } from '../types/ErrorTypes';
import { PartnerOption } from '../types/PartnerTypes';
import { Nullable, Optional } from '../types/UtilTypes';
import {
  OverwatchPartner,
  OverwatchPosition,
  OverwatchQueueType,
} from '../types/overwatch/partner.type';
import { OverwatchTier } from '../types/overwatch/player.type';
import { getOverwatchRegion } from '../utils/application';
import { ErrorMessageMap } from './Alert';
import OptionSwitch from './OptionSwitch';
import OverwatchPlayerProfile from './OverwatchPlayerProfile';
import OverwatchPositionSelect from './OverwatchPositionSelect';
import PasscodeInput from './PasscodeInput';
import QueueTypeSelect from './QueueTypeSelect';
import TierSelect from './TierSelect';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import dayjs from 'dayjs';
import { useMember } from '../queries/member';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  partner?: OverwatchPartner;
  showAlert: (option: IAlertShowOption) => void;
  onClose: () => void;
};

interface OverwatchPartnerRegisterFormProps {
  note?: string;
  passcode: string;
  position: Nullable<OverwatchPosition>;
  tier?: OverwatchTier;
  queueType: OverwatchQueueType;
  options: number;
  recaptcha: Nullable<string>;
  playerName?: string;
}

const OverwatchPartnerRegisterForm: React.FC<Props> = ({
  className,
  partner,
  showAlert,
  onClose: handleClose,
}) => {
  const intl = useIntl();

  const { user } = useMember();
  const {
    addPartner,
    loading: addPartnerLoading,
    error: addPartnerError,
  } = useAddOverwatchPartner();
  const {
    editPartner,
    loading: editPartnerLoading,
    error: editPartnerError,
  } = useEditOverwatchPartner();

  const addPartnerBannedExpiresAt = useMemo(() => {
    const expiresAt = (
      addPartnerError?.graphQLErrors[0]?.extensions?.banErrorData as {
        expiresAt?: string;
      }
    )?.expiresAt;

    if (expiresAt) {
      return dayjs(expiresAt).format('YYYY-MM-DD');
    }
    return undefined;
  }, [addPartnerError?.graphQLErrors]);

  const editPartnerBannedExpiresAt = useMemo(() => {
    const expiresAt = (
      editPartnerError?.graphQLErrors[0]?.extensions?.banErrorData as {
        expiresAt?: string;
      }
    )?.expiresAt;

    if (expiresAt) {
      return dayjs(expiresAt).format('YYYY-MM-DD');
    }
    return undefined;
  }, [editPartnerError?.graphQLErrors]);

  const { filterNote } = useNoteFilter();
  const [{ queueType }, setOverwatchState] = useOverwatchState();

  const {
    hasOption,
    toggleOption,
    rawValue: rawOptions,
  } = usePartnerOptions(partner);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<OverwatchPartnerRegisterFormProps>({
    defaultValues: partner || {
      queueType,
      position: null,
    },
    mode: 'all',
  });

  const [certified, setCertified] = React.useState(false);
  const [animated, setAnimated] = React.useState(false);
  const [isProfilePrivate, setIsProfilePrivate] = React.useState(false);

  const handleAnimated = React.useCallback(() => setAnimated(true), []);

  const handleStripTags = React.useCallback(
    (note: string) => striptags(note),
    []
  );

  const availableTiers = React.useMemo(
    () =>
      [
        'Not selected',
        ...Object.values(OverwatchTier).filter((t) => t !== OverwatchTier.All),
      ] as OverwatchTier[],
    []
  );

  const isTierRequired =
    (partner?.queueType || watch('queueType')) ===
      OverwatchQueueType.CompetitiveOpen ||
    (partner && partner.player.tier && partner.player.tier.length > 0) ||
    isProfilePrivate;

  React.useEffect(() => {
    register('position', { required: true });
    register('tier', { required: isTierRequired });
  }, [isTierRequired]);

  useEffect(() => {
    if (!partner) return;

    const certified = hasOption(PartnerOption.CERTIFIED);
    setCertified(certified);
  }, [partner, hasOption]);

  useEffect(() => {
    if (!partner || !!getValues('tier')) return;

    setValue('tier', partner.player.tier.toUpperCase() as OverwatchTier);
  }, [partner]);

  useEffect(() => {
    if (
      addPartnerError?.graphQLErrors &&
      addPartnerError?.message === ErrorCode.BannedIP
    ) {
      showErrorAlert(addPartnerError?.message as ErrorCode);
    }
  }, [addPartnerError]);

  useEffect(() => {
    if (
      editPartnerError?.graphQLErrors &&
      editPartnerError?.message === ErrorCode.BannedIP
    ) {
      showErrorAlert(editPartnerError?.message as ErrorCode);
    }
  }, [editPartnerError]);

  return (
    <form
      className={classNames(className)}
      onSubmit={handleSubmit(
        handleFormSubmit as SubmitHandler<OverwatchPartnerRegisterFormProps>,
        handleEditFormErrors
      )}
    >
      {partner ? (
        <OverwatchPlayerProfile player={partner.player} />
      ) : (
        <Box>
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={1.5}>
            <Box
              sx={{
                width: { xs: 'auto', lg: '226px' },
              }}
            >
              <label
                className="block text-2xs text-gray-400 mb-2"
                htmlFor="playerName"
              >
                <FormattedMessage id="Player Name" /> (
                {getOverwatchRegion().toUpperCase()})
              </label>
              <input
                type="text"
                disabled={certified}
                placeholder={intl.formatMessage({
                  id: 'Player Name + #Tagline',
                })}
                className={classNames(
                  'block w-full p-3 border rounded border-solid border-gray-600 text-sm focus:outline-none bg-gray-850',
                  {
                    'text-gray-100': !certified,
                    'text-gray-600': certified,
                  }
                )}
                {...register('playerName', { required: true })}
              />
            </Box>
          </Stack>
        </Box>
      )}

      <div className="flex lg:space-x-3 sm:flex-col lg:items-stretch items-start">
        <div>
          <label className="block text-2xs text-gray-400 mb-2 mt-3">
            <FormattedMessage id="Role" />
          </label>
          <OverwatchPositionSelect
            position={watch('position')}
            onChange={handlePosition}
          />
        </div>

        {!partner && (
          <div>
            <label className="block text-2xs text-gray-400 mb-2 mt-3">
              <FormattedMessage id="Queue Type" />
            </label>
            <QueueTypeSelect
              queueTypes={Object.values(OverwatchQueueType)}
              selected={watch('queueType')}
              onChange={handleQueueType}
              className="inline-block"
            />
          </div>
        )}

        <Grow in={isTierRequired} unmountOnExit>
          <div>
            <label className="block text-2xs text-gray-400 mb-2 mt-3">
              <FormattedMessage id="Tier" />
            </label>
            <TierSelect
              tiers={availableTiers}
              selected={watch('tier')}
              onChange={handleTier}
              className="inline-block"
            />
          </div>
        </Grow>

        <div className="flex flex-col">
          <label className="block text-2xs text-gray-400 mb-2 mt-3">
            <FormattedMessage id="MIC" />
          </label>

          <div className="flex-1 flex items-center">
            <OptionSwitch
              enabled={hasOption(PartnerOption.MIC)}
              onChange={handleChangeOption}
            />
          </div>
        </div>
      </div>

      <label className="block text-2xs text-gray-400 mb-2 mt-3">
        <FormattedMessage id="Note" />
      </label>
      <input
        type="text"
        autoComplete="nope"
        className="w-full p-3 border rounded border-solid border-gray-600 text-gray-100 text-sm focus:outline-none bg-gray-850"
        placeholder={intl.formatMessage({
          id: 'Looking for a DUO partner.',
        })}
        {...register('note', { setValueAs: handleStripTags })}
      />

      <Collapse
        in={!certified}
        timeout={animated ? 'auto' : 0}
        addEndListener={handleAnimated}
      >
        <PasscodeInput
          className="w-44"
          {...register('passcode', {
            required: {
              value: !certified,
              message: intl.formatMessage({ id: `Can't be blank` }),
            },
            minLength: {
              value: 4,
              message: intl.formatMessage({ id: 'Enter 4 digits, please.' }),
            },
            maxLength: {
              value: 4,
              message: intl.formatMessage({ id: 'Enter 4 digits, please.' }),
            },
            pattern: {
              value:
                /^(?!(\d)\1\1\1|(0123)|(1234)|(2345)|(3456)|(4567)|(5678)|(6789)|(7890)|(0987)|(9876)|(8765)|(7654)|(6543)|(5432)|(4321)|(3210)).*$/,
              message: intl.formatMessage({
                id: 'Cannot use consecutive number sequences or repetitions.',
              }),
            },
          })}
        />
      </Collapse>

      {errors?.passcode && (
        <span className="block text-red-500 m-0 my-0.5 text-sm">
          {errors.passcode.message}
        </span>
      )}

      {!partner && (
        <div className="flex flex-row items-center mt-3">
          <img src="//opgg-desktop-data.akamaized.net/download/duo/images/icons/info.svg" />
          <p className="text-2xs text-red-500 ml-1 break-words">
            <FormattedMessage id="Acts such as insults, defamation, and sexual harassment against others may be subject to legal punishment when reported by the other party." />
          </p>
        </div>
      )}

      <div className="flex mt-4 ml-auto">
        <button
          type="button"
          className="cancel flex-1 p-0 rounded"
          onClick={() => {
            dataLayer(
              {
                op_event: GTMEventInfoType.CLICK_CANCLE_POST,
              },
              GTMEventType.CLICK
            );
            handleClose();
          }}
          tabIndex={-1}
        >
          <FormattedMessage id="Cancel" />
        </button>
        <button
          type="submit"
          className="ml-3 flex-1 p-0 rounded h-12 button button--submit"
          tabIndex={-1}
          disabled={addPartnerLoading || editPartnerLoading}
        >
          {addPartnerLoading || editPartnerLoading ? (
            <FormattedMessage id="Saving..." />
          ) : (
            <FormattedMessage id={partner ? 'Edit' : 'Registration'} />
          )}
        </button>
      </div>
    </form>
  );

  function handleChangeOption(): void {
    toggleOption(PartnerOption.MIC);
  }

  function handlePosition(position: OverwatchPosition): void {
    setValue('position', position);
  }

  function handleTier(tier: OverwatchTier): void {
    setValue('tier', tier);
  }

  function handleQueueType(queue: OverwatchQueueType): void {
    setValue('queueType', queue);
  }

  function handleEditFormErrors(
    errors: FieldErrors<{
      playerName: string;
      position: OverwatchPosition;
      tier: OverwatchTier;
      passcode: string;
    }>
  ): void {
    if (errors.playerName) {
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.PLAYER],
        },
        errorCode: ErrorCode.PLAYER,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'playerName',
        },
        GTMEventType.SCREEN_VIEW
      );
    } else if (errors.position) {
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.ROLE],
        },
        errorCode: ErrorCode.ROLE,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'role',
        },
        GTMEventType.SCREEN_VIEW
      );
    } else if (errors.tier) {
      showAlert({
        message: {
          key: ErrorMessageMap[ErrorCode.TIER],
        },
        errorCode: ErrorCode.TIER,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'tier',
        },
        GTMEventType.SCREEN_VIEW
      );
    } else if (errors.passcode) {
      const errCode =
        ErrorCode[
          errors.passcode.type === 'pattern'
            ? 'InvalidPatternPasscode'
            : 'PASSWORD'
        ];
      showAlert({
        message: {
          key: ErrorMessageMap[errCode],
        },
        errorCode: errCode,
      });
      dataLayer(
        {
          op_event: GTMEventInfoType.ERROR_ON_POST,
          op_event_value: 'passcode',
        },
        GTMEventType.SCREEN_VIEW
      );
    }
  }

  async function handleFormSubmit(
    data: OverwatchPartnerRegisterProps & OverwatchPartnerUpdateProps
  ): Promise<void> {
    if (addPartnerLoading || editPartnerLoading) return;

    try {
      const submit = partner ? handleEditPartner : handleAddPartner;

      const response = await submit({
        ...data,
        note: filterNote(data.note),
        passcode: certified ? '' : data.passcode,
      });

      if (!response) return;

      setOverwatchState({ queueType: data.queueType });

      handleClose();
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === ErrorCode.PrivateProfile) {
          setIsProfilePrivate(true);
          showErrorAlert(ErrorCode.PrivateProfileChooseTier);
        } else {
          showErrorAlert(err.message as ErrorCode);
        }
      }
    }
  }

  async function handleEditPartner(
    data: OverwatchPartnerUpdateProps
  ): Promise<Optional<OverwatchPartner>> {
    try {
      if (!partner) {
        throw new Error('userId must not be a nullable value.');
      }

      const response = await editPartner({
        variables: {
          ...data,
          id: partner.id,
          options: rawOptions,
          queueType: data.queueType as OverwatchQueueType,
          tier: data.tier && data.tier.length > 0 ? data.tier : undefined,
        },
      });

      if (!response.data || !response.data.updateOverwatchPartner) return;

      if (response.data?.updateOverwatchPartner) {
        showAlert({
          message: {
            key: '{name} player updated successfully.',
            value: { name: partner.player.name },
          },
          errorCode: null,
        });

        dataLayer(
          {
            op_event: GTMEventInfoType.SUBMIT_EDIT_POST,
          },
          GTMEventType.SUBMIT
        );
        handleClose();
      }

      return response.data.updateOverwatchPartner;
    } catch (e) {
      throw e;
    }
  }

  async function handleAddPartner(
    data: OverwatchPartnerRegisterProps
  ): Promise<Optional<OverwatchPartner>> {
    if (
      !data.playerName ||
      (!certified && data.playerName.indexOf('#') === -1)
    ) {
      throw new Error('PlayerNotFound');
    }

    try {
      const [playerName, tagLine] = certified
        ? (getValues('playerName') ?? '').split('#')
        : data.playerName.split('#');

      const response = await addPartner({
        variables: {
          ...data,
          region: getOverwatchRegion(),
          options: rawOptions,
          queueType: data.queueType as OverwatchQueueType,
          playerName,
          tagLine,
        },
      });

      if (!response.data || !response.data.addOverwatchPartner) return;

      dataLayer(
        {
          op_event: GTMEventInfoType.SUBMIT_POST,
        },
        GTMEventType.SUBMIT
      );

      return response.data.addOverwatchPartner;
    } catch (e) {
      throw e;
    }
  }

  function showErrorAlert(errorCode: ErrorCode): void {
    const messageKey =
      ErrorMessageMap[errorCode] || ErrorCode.UnexpectedException;

    const value = (() => {
      if (errorCode === ErrorCode.PartnerCreationLimited) {
        return {
          minutes: Boolean(getValues('passcode')) ? 60 : 10,
        };
      } else if (errorCode === ErrorCode.BannedIP) {
        return {
          expiresAt: addPartnerBannedExpiresAt || editPartnerBannedExpiresAt,
        };
      }
      return {};
    })() as Record<string, string>;

    showAlert({
      message: {
        key: messageKey,
        value,
      },
      errorCode,
    });
  }
};

export default OverwatchPartnerRegisterForm;
