import { gql, useMutation } from '@apollo/client';
import {
  OverwatchPartner,
  OverwatchPosition,
  OverwatchQueueType,
} from '../../types/overwatch/partner.type';
import { OverwatchTier } from '../../types/overwatch/player.type';
import { Nullable, Optional } from '../../types/UtilTypes';

const ADD_OVERWATCH_PARTNER = gql`
  mutation AddOverwatchPartner(
    $note: String
    $passcode: String!
    $position: String!
    $region: String!
    $playerName: String!
    $tagLine: String!
    $queueType: OverwatchQueueType!
    $tier: OverwatchTierType
    $options: Int!
  ) {
    addOverwatchPartner(
      note: $note
      passcode: $passcode
      position: $position
      region: $region
      playerName: $playerName
      tagLine: $tagLine
      queueType: $queueType
      tier: $tier
      options: $options
    ) {
      id
    }
  }
`;

const DELETE_OVERWATCH_PARTNER = gql`
  mutation DeleteOverwatchPartner($id: ID!, $passcode: String) {
    deleteOverwatchPartner(id: $id, passcode: $passcode) {
      id
    }
  }
`;

const UPDATE_OVERWATCH_PARTNER = gql`
  mutation UpdateOverwatchPartner(
    $id: ID!
    $note: String
    $passcode: String!
    $position: String!
    $queueType: OverwatchQueueType!
    $tier: OverwatchTierType
    $options: Int!
  ) {
    updateOverwatchPartner(
      id: $id
      note: $note
      passcodeConfirm: $passcode
      position: $position
      queueType: $queueType
      tier: $tier
      options: $options
    ) {
      id
    }
  }
`;

export interface OverwatchPartnerProps {
  note: Nullable<string>;
  passcode: string;
  position: Nullable<OverwatchPosition>;
  tier: Optional<OverwatchTier>;
  queueType: OverwatchQueueType;
  options: number;
}

export interface OverwatchPartnerRegisterProps extends OverwatchPartnerProps {
  region: string;
  playerName: string;
  tagLine: string;
  recaptcha: Nullable<string>;
}

export interface OverwatchPartnerUpdateProps extends OverwatchPartnerProps {
  id: string;
}

export function useAddOverwatchPartner() {
  const [addPartner, { data, ...rest }] = useMutation<
    { addOverwatchPartner: OverwatchPartner },
    OverwatchPartnerRegisterProps
  >(ADD_OVERWATCH_PARTNER);

  return {
    addPartner,
    partner: data?.addOverwatchPartner,
    ...rest,
  };
}

export function useDeleteOverwatchPartner() {
  const [deletePartner, { data, ...rest }] = useMutation<{
    deleteOverwatchPartner: OverwatchPartner;
  }>(DELETE_OVERWATCH_PARTNER);

  return {
    deletePartner,
    partner: data?.deleteOverwatchPartner,
    ...rest,
  };
}

export function useEditOverwatchPartner() {
  const [editPartner, { data, ...rest }] = useMutation<
    { updateOverwatchPartner: OverwatchPartner },
    OverwatchPartnerUpdateProps
  >(UPDATE_OVERWATCH_PARTNER);

  return {
    editPartner,
    partner: data?.updateOverwatchPartner,
    ...rest,
  };
}
